'use client';

import React, { useEffect, useRef } from 'react';
import { Locale } from '../../../i18n-config';

type Props = {
  lang: Locale;
};

const Widget: React.FC<Props> = (props: Props) => {
  const scriptRoot = useRef<HTMLDivElement | null>(null);

  const script = props.lang.startsWith('en')
    ? `<script id="LouiseWidget">
      !(function () { function g() {
          var script = document.createElement('script'), code = 'cce0fa23-5083-41fb-ae07-10954301fef6',url = 'https://cdn.pinkswan.ch/application/LOUISEBOT/23082315255659_LouiseBot_widget.js', l = localStorage.setItem('ps_widget' , code);
          (script.type = 'text/javascript'),(script.async = !0),(script.src = url);
          document.getElementsByTagName('head')[0].appendChild(script);
      }
      document.readyState === 'complete' ? g() : window.attachEvent ? window.attachEvent('onload', g)
      : window.addEventListener('load', g, !1);
      })();
    </script>`
    : `
    <script id="LouiseWidget">
    !(function () { function g() {
      var script = document.createElement('script'), code = '1bcd8bf6-83de-4098-956f-1bf389716ae2',url = 'https://cdn.pinkswan.ch/application/LOUISEBOT/23082315255659_LouiseBot_widget.js', l = localStorage.setItem('ps_widget' , code);
      (script.type = 'text/javascript'),(script.async = !0),(script.src = url);
      document.getElementsByTagName('head')[0].appendChild(script);
    }
    document.readyState === 'complete' ? g() : window.attachEvent ? window.attachEvent('onload', g)
    : window.addEventListener('load', g, !1);
    })();
   </script>
   `;

  useEffect(() => {
    if (scriptRoot.current) {
      const range = document.createRange();
      const documentFragment = range.createContextualFragment(script);
      scriptRoot.current.appendChild(documentFragment);
    }
  }, [script]);

  return <div ref={scriptRoot}></div>;
};

export default Widget;
