'use client';

import './style.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Utils from '@/utils/index';
import { useSearchParams } from 'next/navigation';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

type ConsentType = {
  ad_storage: 'granted' | 'denied';
  analytics_storage: 'granted' | 'denied';
  personalization_storage: 'granted' | 'denied';
  functionality_storage: 'granted' | 'denied';
  security_storage: 'granted' | 'denied';
};

type checkBoxesType = {
  necessary: boolean;
  analytics: boolean;
  preferences: boolean;
  marketing: boolean;
};

const ConsentManager = () => {
  const [open, setOpen] = useState(false);
  const searchParams = useSearchParams();

  const consentRef = useRef<HTMLDivElement | null>(null);

  const [checkBoxes, setCheckBoxes] = useState<checkBoxesType>({
    necessary: true,
    analytics: true,
    preferences: true,
    marketing: false,
  });

  const handlePersistConsents = useCallback((consent: ConsentType) => {
    if (typeof Storage !== 'undefined' && window.localStorage && typeof localStorage !== 'undefined') {
      let currentConsent = localStorage.getItem('consentMode');

      if (!currentConsent && (window as unknown as Window & typeof globalThis)) {
        window.gtag('consent', 'update', consent);

        localStorage.setItem('consentMode', JSON.stringify(consent));

        if (searchParams) Utils.utmManager(searchParams);

        document.body.classList.remove('consent-manager-open');

        setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConsents = useCallback(
    (type: 'all' | 'selection' | 'none', checkBoxes: any) => {
      const enumConsent: { all: ConsentType; selection: ConsentType; none: ConsentType } = {
        all: {
          ad_storage: 'granted',
          analytics_storage: 'granted',
          personalization_storage: 'granted',
          functionality_storage: 'granted',
          security_storage: 'granted',
        },
        selection: {
          ad_storage: checkBoxes.marketing ? 'granted' : 'denied',
          analytics_storage: checkBoxes.analytics ? 'granted' : 'denied',
          personalization_storage: checkBoxes.preferences ? 'granted' : 'denied',
          functionality_storage: 'granted',
          security_storage: 'granted',
        },
        none: {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          personalization_storage: 'denied',
          functionality_storage: 'granted',
          security_storage: 'granted',
        },
      };

      handlePersistConsents(enumConsent[type]);
    },

    [handlePersistConsents]
  );

  useEffect(() => {
    const handleOutside = (e: MouseEvent) => {
      const button = document.querySelector('button');

      if (e.target !== button && consentRef.current && !consentRef.current.contains(e.target as Node))
        handleConsents('all', checkBoxes);
    };

    if (typeof Storage !== 'undefined' && window.localStorage && typeof localStorage !== 'undefined') {
      let currentConsent = localStorage.getItem('consentMode');

      if (!currentConsent)
        setTimeout(() => {
          setOpen(true);

          document.body.classList.add('consent-manager-open');
        }, 3000);

      document.addEventListener('click', handleOutside);
    }

    return () => {
      if (typeof Storage !== 'undefined' && window.localStorage && typeof localStorage !== 'undefined') {
        document.body.classList.remove('consent-manager-open');

        setOpen(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleConsents]);

  if (!open) return null;

  return (
    <div className={`consent-manager ${open ? 'open' : ''}`} ref={consentRef}>
      <div className="flex justify-center mb-3">
        <Image
          src={'https://cdn.pinkswan.ch/application/PIL/24050806320483_American_Cookie_-_1372x1347.png'}
          alt="cookie"
          width={60}
          height={60}
        />
      </div>

      <p className="self-stretch text-center text-white text-lg font-semibold font-['Figtree'] leading-7 md:w-3/4 mx-auto">
        We use cookies to provide you with the best possible experience. They also allow us to analyze user behavior in
        order to constantly improve the website for you.
      </p>
      <div className="buttons">
        <button
          className="px-[17px] py-[9px] bg-sky-500 rounded-md shadow justify-center items-center flex text-gray-900 text-sm font-medium font-['Figtree'] leading-tight"
          onClick={() => handleConsents('all', checkBoxes)}
        >
          Accept All
        </button>
        <button
          className="px-[17px] py-[9px] bg-gray-700 rounded-md shadow justify-center items-center flex text-sky-300 text-sm font-medium font-['Figtree'] leading-tight"
          onClick={() => handleConsents('selection', checkBoxes)}
        >
          Accept Selection
        </button>
        <button
          className="px-[17px] py-[9px] rounded-md shadow border border-gray-600 justify-center items-center flex text-sky-300 text-sm font-medium font-['Figtree'] leading-tight"
          onClick={() => handleConsents('none', checkBoxes)}
        >
          Reject All
        </button>
      </div>
      <div className="checklists">
        <div>
          <input type="checkbox" defaultChecked={checkBoxes.necessary} disabled />
          <label>
            <span>Necessary</span>
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            defaultChecked={checkBoxes.analytics}
            onChange={(e) => setCheckBoxes((prev) => ({ ...prev, analytics: e.target.checked }))}
            id="analytics"
          />
          <label htmlFor="analytics">
            <span>Analytics</span>
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            defaultChecked={checkBoxes.preferences}
            onChange={(e) => setCheckBoxes((prev) => ({ ...prev, preferences: e.target.checked }))}
            id="preferences"
          />
          <label htmlFor="preferences">
            <span>Preferences</span>
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            defaultChecked={checkBoxes.marketing}
            onChange={(e) => setCheckBoxes((prev) => ({ ...prev, marketing: e.target.checked }))}
            id="marketing"
          />
          <label htmlFor="marketing">
            <span>Marketing</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ConsentManager;
