'use client';

import Image from 'next/image';
import LanguageSwitcher from '../common/LanguageSwitcher';
import Link from 'next/link';
import { Locale } from '../../../i18n-config';
import React from 'react';
import { useTranslation } from '@/i18n/client';

type Props = {
  language: Locale;
};

const Footer = ({ language }: Props) => {
  const { t } = useTranslation(language);

  const footerLinks = [
    {
      sectionTitle: t('Layout/Footer/key2'),
      links: [
        {
          title: t('Layout/Footer/key3'),
          href: `/${language}/features#auth-status-control`,
        },
        {
          title: t('Layout/Footer/key4'),
          href: `/${language}/features#auth-templates`,
        },
        {
          title: t('Layout/Footer/key5'),
          href: `/${language}/features#loyalty-membership-card`,
        },
      ],
    },
    {
      sectionTitle: t('Layout/Footer/key6'),
      links: [
        {
          title: t('Layout/Footer/key7'),
          href: `/${language}/about-us`,
        },
        // {
        //   title: t('Layout/Footer/key8'),
        //   href: `/${language}/careers`,
        // },
        // {
        //   title: t('Layout/Footer/key9'),
        //   href: `/${language}/trust-center`,
        // },
        {
          title: t('Layout/Footer/key10'),
          href: `/${language}/privacy-policy`,
        },
      ],
    },
    {
      sectionTitle: t('Layout/Footer/key11'),
      links: [
        {
          title: t('Layout/Footer/key12'),
          href: `/${language}/contact-us`,
        },
        {
          title: t('Layout/Footer/key13'),
          href: `/${language}/faq`,
        },
        // {
        //   title: t('Layout/Footer/key14'),
        //   href: `/${language}/our-commitment`,
        // },
      ],
    },
  ];

  return (
    // <footer className="bg-[#374151]">
    <footer className="min-h-[511px] bg-gray-950">
      <div className="container max-w-full lg:py-16 py-12">
        <div className="flex lg:flex-row flex-col lg:items-center border-solid border-b border-gray-900 pb-8">
          <div className="basis-1/2">
            <div className="w-[219px] h-10 justify-start items-center gap-3 inline-flex">
              <Link href={`/`} className="icon shrink-0">
                <Image
                  // src={'https://cdn.pinkswan.ch/application/UMD/24072109215122_Logo.svg'}
                  src={'https://cdn.pinkswan.ch/application/UMD/24073010001525_On_Hover_logo.svg'}
                  width={0}
                  height={0}
                  sizes="100vw"
                  alt="UMD"
                  className="w-full h-full"
                />
              </Link>
              {/* <div className="text-white text-lg font-semibold font-['Figtree'] leading-7">Super User Manager</div> */}
            </div>
          </div>
          <div className="basis-1/2">
            <div className="lg:text-right text-[#D1D5DB] text-lg font-semibold font-['Figtree'] leading-7">
              {t('Layout/Footer/key1')}
            </div>
          </div>
        </div>

        <div className="flex lg:flex-row flex-col lg:py-12 py-10 gap-6 border-b border-gray-900">
          {footerLinks.map((sec) => (
            <div key={sec.sectionTitle} className="basis-1/3 flex flex-col gap-4">
              <div className="text-[#89909B] text-sm font-semibold font-['Figtree'] uppercase leading-tight">
                {sec.sectionTitle}
              </div>
              {sec.links.map((link) => (
                <Link
                  key={link.title}
                  href={link.href}
                  className="block text-[#D1D5DB] text-base font-normal font-['Figtree'] leading-normal"
                >
                  {link.title}
                </Link>
              ))}
            </div>
          ))}
        </div>

        <div className="flex lg:flex-row flex-col lg:items-center pt-8 gap-12">
          <div className="basis-1/2">
            <div className="text-gray-400 font-normal text-sm font-['Figtree'] leading-normal">
              © {new Date().getFullYear()} Super User Manager. {t('Layout/Footer/key15')}.
            </div>
          </div>
          {/* <div className="basis-1/2 flex lg:justify-end">
            <LanguageSwitcher />
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
